import React, { useEffect, useCallback, useMemo } from "react";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";
import Oneko from "./components/Oneko"; // Adjust the path as necessary
import "./App.css"; // Make sure your CSS is appropriately imported
import confetti from "canvas-confetti";

function App() {
  const count = 200;

  const defaults = useMemo(
    () => ({
      spread: 360,
      ticks: 100,
      gravity: 0,
      decay: 0.94,
      startVelocity: 30,
    }),
    []
  ); // Empty dependency array means this object is created once and never changes

  const handleClick = useCallback(
    (event) => {
      const { clientX, clientY } = event;

      // Calculate click position as a ratio of the window size to adjust origin dynamically
      const origin = {
        x: clientX / window.innerWidth,
        y: clientY / window.innerHeight,
      };

      function fire(particleRatio, opts) {
        confetti(
          Object.assign({}, defaults, opts, {
            particleCount: Math.floor(count * particleRatio),
          })
        );
      }

      // Fire confetti with the dynamic origin based on click position
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
        origin,
      });

      fire(0.2, {
        spread: 60,
        origin,
      });

      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        origin,
      });

      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        origin,
      });

      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        origin,
      });
    },
    [count, defaults]
  ); // Add count and defaults to the dependency array

  useEffect(() => {
    // Attach the event listener to the entire document or a specific element
    document.addEventListener("click", handleClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]); // handleClick is now stable and won't cause unnecessary re-renders

  return (
    <div className="App">
      <Header />
      <Content />
      <Footer />
      <Oneko />
      {/* Insert InteractiveComponent here if needed */}
    </div>
  );
}

export default App;
