import React from "react";

function Header() {
  return (
    <header id="site_header">
      <h1 style={{ wordBreak: "break-word" }}>
        alexander 'aflaton' al&#8209;feghali
      </h1>
      <nav>
        <span>
          <a href="https://al-fegha.li/" class="active">
            home
          </a>
        </span>
        <span> | </span>
        <span>
          <a href="https://blog.al-fegha.li/" rel="noreferrer" target="_blank">
            blog
          </a>
        </span>
        <span> | </span>
        <span>
          <a
            href="https://alxfgh.substack.com/"
            rel="noreferrer"
            target="_blank"
          >
            substack
          </a>
        </span>
        <span> | </span>
        <span>
          <a
            href="https://scholar.google.com/citations?user=6Sewf2kAAAAJ&hl=en&oi=ao"
            rel="noreferrer"
            target="_blank"
          >
            papers
          </a>
        </span>
        <span> | </span>
        <span>
          <a
            href="https://www.linkedin.com/in/alexander-al-feghali/"
            rel="noreferrer"
            target="_blank"
          >
            linkedin
          </a>
        </span>
        <span> | </span>
        <span>
          <a href="https://github.com/alxfgh" rel="noreferrer" target="_blank">
            github
          </a>
        </span>
      </nav>
    </header>
  );
}

export default Header;
