import React from "react";

function Footer() {
  return (
    <footer>
      <div class="footer-kitten"></div>
      <div>
        <span>
          <a
            href="https://twitter.com/AbuTarator"
            rel="noreferrer"
            target="_blank"
          >
            twitter
          </a>
        </span>
        <span> | </span>
        <span>
          <a
            href="https://www.tiktok.com/@abutarator"
            rel="noreferrer"
            target="_blank"
          >
            tiktok
          </a>
        </span>
        <span> | </span>
        <span>
          <a
            href="https://www.last.fm/user/alxfgh"
            rel="noreferrer"
            target="_blank"
          >
            last.fm
          </a>
        </span>
        <span> | </span>
        <span>
          <a
            href="https://www.goodreads.com/user/show/58009061-alexander-al-feghali"
            rel="noreferrer"
            target="_blank"
          >
            goodreads
          </a>
        </span>
        <span> | </span>
        <span>
          <a
            href="https://wakatime.com/@alxfgh"
            rel="noreferrer"
            target="_blank"
            alt="colab not counted"
          >
            wakatime
          </a>
        </span>
        <span> | </span>
        <span>
          <a
            href="https://www.youtube.com/playlist?list=PLZ7eIDG2axZdm97fM8c5lfYLZn-jA7l69"
            rel="noreferrer"
            target="_blank"
          >
            playlist
          </a>
        </span>
      </div>
      <div>
        <p>
          design inspired by{" "}
          <a rel="noreferrer" target="_blank" href="https://maia.crimew.gay/">
            maia crimew
          </a>
          , pointer following kitten from{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://github.com/adryd325/oneko.js"
          >
            adryd325/oneko.js
          </a>{" "}
          and adapted to react by{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://github.com/alxfgh/Oneko.js-React/blob/main/Oneko_react.js"
          >
            abu tarator
          </a>
        </p>
      </div>
      <div className="badges">
        <img src="gifs/smile.gif" alt="smile" title="smile" />
        <img src="gifs/nsie.gif" alt="hehe" title="lol" />
        <a rel="noreferrer" href="https://dhmo.org" target="_blank">
          <img src="/gifs/dhmo.gif" alt="dhmo" title="dhmo" />
        </a>
        <img src="gifs/alienow.gif" alt="alien now" title="alien now" />
        <img src="gifs/nvidia.gif" alt="nvidia" title="nvidia" />
        <img src="gifs/bestviewedwith.gif" alt="hehe" title="lol" />
        <a
          rel="noreferrer"
          href="https://cyber.dabamos.de/88x31"
          target="_blank"
        >
          <img src="gifs/88x31.gif" alt="88x31" title="badges" />
        </a>
        <img src="gifs/linuxnow2.gif" alt="linux now" title="linux now" />
        <a
          rel="noreferrer"
          href="https://www.latex-project.org/"
          target="_blank"
        >
          <img src="/gifs/latex.gif" alt="latex" title="latex" />
        </a>
        <img src="gifs/bestviewedopen.gif" alt="hehe" title="lol" />
        <a rel="noreferrer" href="http://www.slsknet.org/news/" target="_blank">
          <img src="/gifs/soulseek.gif" alt="soulseek" title="soulseek" />
        </a>
        <a
          rel="noreferrer"
          href="https://www.youtube.com/watch?v=T_lC2O1oIew&vl"
          target="_blank"
        >
          <img
            src="gifs/plasticlove.gif"
            alt="plastic love"
            title="plastic love"
          />
        </a>
        <img src="gifs/notepad2.gif" alt="notepad++" title="notepad++" />
      </div>
    </footer>
  );
}

export default Footer;
