import React from "react";
import donaldImage from "./donald.png"; // Ensure you import the image correctly

function Content() {
  return (
    <div id="content">
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <div
          style={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "30%",
            minWidth: "200px",
            maxWidth: "70%",
          }}
        >
          <img src={donaldImage} alt="funny donald duck face" />
        </div>
        <div style={{ flexGrow: 2, flexShrink: 2, flexBasis: "60%" }}>
          <p>hello and welcome to my website</p>
          <p>
            i am currently a phd student in the chemistry department at mcgill
            university co-supervised by dr. amy blum and dr. gonzalo cosa
          </p>
          <p>
            i am interested in a lot of things, including ai (statistics),
            photonics, chips, ai (web dev), post-modern natural philosophy, and
            smooth jazz
          </p>
          <p>
            i build text, vision and audio medical ai at{" "}
            <a href="https://www.mdose.ai/" rel="noreferrer" target="_blank">
              mdose.ai
            </a>{" "}
            and general scientific ai at{" "}
            <a href="https://rosal.in/" rel="noreferrer" target="_blank">
              rosal.in
            </a>
          </p>
          <p>
            i also build fun ai at{" "}
            <a href="https://www.tkhaial.com/" rel="noreferrer" target="_blank">
              tkhaial.com
            </a>
          </p>
          <p>
            i also make it easier to build and share websites, presentations and
            pdfs at{" "}
            <a href="https://www.kabir.host/" rel="noreferrer" target="_blank">
              kabir.host
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Content;
